'use client';
import Button from '../Button/Button';
import { Typography } from '../Typography/Typography';
import cn from 'classnames';
import { FC, forwardRef, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { PositionDetails, TooltipProps } from './Tooltip.entities';
import styles from './Tooltip.module.css';
import { getDialogCoords } from './Tooltip.utils';

export const CLOSE_DELAY = 250;

export const Tooltip: FC<TooltipProps> = forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      children,
      text,
      dialogId,
      extraText,
      requestPos = 'bottom',
      darkMode,
      className,
      customTooltipTextClassName,
      ...rest
    }: TooltipProps,
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [positionDetails, setPositionDetails] = useState<PositionDetails>({
      coords: [0, 0],
      name: requestPos,
    });
    const wrapperRef = useRef<HTMLButtonElement>(null);
    let closeDelay: NodeJS.Timeout;

    const clearTO = () => clearTimeout(closeDelay);

    const openDialog = () => {
      if (wrapperRef.current === null) return;
      clearTO();
      setPositionDetails(getDialogCoords(wrapperRef.current, requestPos));
      setIsOpen(true);
      window.addEventListener('scroll', beginCloseDialog);
      document.addEventListener('wheel', beginCloseDialog);
      //on scroll event listener check how far the page was scrolled
    };

    const closeDialog = () => {
      window.removeEventListener('scroll', beginCloseDialog);
      document.removeEventListener('wheel', beginCloseDialog);
      setIsOpen(false);
    };

    const beginCloseDialog = () => {
      clearTO();
      closeDelay = setTimeout(() => {
        closeDialog();
      }, CLOSE_DELAY);
    };

    const toggleDialog = (show: boolean) => {
      clearTO();
      if (show) {
        openDialog();
      } else {
        closeDialog();
      }
    };
    const classNames = cn(styles.wrpr, className);
    return (
      <div
        className={classNames}
        onMouseEnter={() => openDialog()}
        onMouseLeave={() => beginCloseDialog()}
        ref={ref}
        {...rest}
      >
        <Button
          aria-controls={dialogId}
          aria-expanded={isOpen}
          aria-haspopup="dialog"
          className={styles.infoIcon}
          onClick={() => toggleDialog(!isOpen)}
          ref={wrapperRef}
          type="button"
        >
          {children}
        </Button>
        {isOpen &&
          createPortal(
            <div
              className={`${styles.dialog} ${darkMode ? styles.darkMode : ''} ${
                positionDetails.name
              } ${customTooltipTextClassName ? customTooltipTextClassName : ''}`}
              id={dialogId}
              onMouseEnter={() => clearTimeout(closeDelay)}
              onMouseLeave={() => beginCloseDialog()}
              role="dialog"
              style={{ top: positionDetails.coords[1], left: positionDetails.coords[0] }}
            >
              <Typography variant="body4">{text}</Typography>
              {extraText}
            </div>,
            document.body,
          )}
      </div>
    );
  },
);

Tooltip.displayName = 'Tooltip';

export default Tooltip;
